<template>
    <div class="edu-frame">
        <edu-nav-bar title="打印课程列表" :show="show" @showPopup="show=true"
                     @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
            <div class="edu-group" style="margin: 20px auto 15px;">
                <div class="title-tips">您所报读的班级：</div>
            </div>
            <div class="class-list">
                <table class="class-table">
                    <thead style="background-color: #e0eaf3;font-weight: bold;">
                    <tr class="class-row">
                        <th style="width: 30%">所属课程</th>
                        <th style="width: 27%">上课时间</th>
                        <th style="width: 21%">上课地点</th>
                        <th style="width: 22%">开学时间</th>
                    </tr>
                    </thead>
                    <template v-for="(master, key) in regClassMasters">
                        <tr :key="key" style="background-color: white;border-bottom: 1px solid #F7F7F7;">
                            <td class="class-detail" colspan="4">
                                <div class="class-detail-title">单号：{{key}}</div>
                                <div class="class-detail-more">提交时间：{{master[0].billDate | filterDate}}</div>
                            </td>
                        </tr>
                        <tr v-for="(detail, detailIndex) in master" :key="detailIndex" style="text-align: center;background-color: white;">
                            <td>{{detail.className}}</td>
                            <td>{{detail.classTime}}</td>
                            <td>{{detail.classRoomName}}</td>
                            <td>{{detail.termBegins}}</td>
                        </tr>
                        <tr :key="'tr_'+key" style="height: 10px;"></tr>
                    </template>

                </table>
            </div>

        </div>
    </div>
</template>
<script>
    import EduNavBar from "@/components/EduNavBar";
    import BaomingApi from "@/api/BaomingApi";
    import Tools from "@/api/Tools";
    import EduConstants from '@/api/EduConstants';
    import XEUtils from "xe-utils";

    export default {
        components: {
            EduNavBar,
        },
        filters: {
            filterDate(date) {
                return XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm:ss')
            }
        },
        data() {
            return {
                cust: Tools.getCurCust(),
                LOGINTYPE_CHILD: EduConstants.LOGINTYPE_CHILD,
                show: false,
                regClassMasters: [],
                datas: [],
                payTotal: 0,
                reportData: '',
                refunds: [],
                loading: false
            }
        },
        methods: {
            getAlreadyBaoduClasses() {
                BaomingApi.getAlreadyBaoduClasses({
                    custId: this.cust.custId,
                    loginType: this.cust.loginType,
                    reportType: 'ybdbj'
                }).then(response => {
                    // console.log(response.res)
                    this.regClassMasters = response.res.datas
                    this.payTotal = response.res.payTotal
                    this.reportData = response.res.reportData
                })
            }
        },
        mounted() {
            this.getAlreadyBaoduClasses()
        }
    }
</script>
<style scoped>
    .content-frame {
        height: calc(100% - 46px);
    }

    .class-list {
        width: 95%;
        border-radius: 8px;
        border: 1px solid lightgrey;
        box-shadow: 1px 2px 2px #ddd;
        margin: 10px auto;
        padding: 8px 0px;
        background-color: #cde7f7;
    }

    .title-tips {
        margin-left: 2%;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        color: black;
    }

    .class-table {

        font-size: 14px;
        width: 96%;
        margin: 0 auto;

    }

    .class-split {
        width: 95%;
        height: 1px;
        border-top: 2px dotted lightgrey;
    }
</style>
